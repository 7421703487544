import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Image from '../components/FeaturedImage'
// import SubCats from '../components/subCats'
import Grid from '../components/grid'
import GridItem from '../components/gridItem'

const IndexPage = ({data}) => {
  return (
    <Layout pageTitle="Книги на английском">
      <Grid>
        { data.allWpLatteproduct.nodes.map( product => {
          return (
            <GridItem
              key={product.id}
              uri={`/product/${product.slug}/`}
              title={ product.title }
            >
              <Image product={product}/>
            </GridItem>
          )
        })}
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpLatteproduct {
      nodes {
        id
        title
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
